import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { contentHolder, exampleSection, serviceListItem, carouselImage } from "./services.module.css"
import { Row, Col, ListGroup, Carousel } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export const ServicePageQuery = graphql`query ServicePageQuery {
  allFile(
    filter: {extension: {eq: "png"}, dir: {regex: "/highlights\\/service/"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}`

const ServicePage = ({ data, services }) => {
  return (
    <Layout>
      <Seo title="Residential & Commercial Service" />
      <div className={contentHolder}>
        <h2 className={"page-title"}>Residential Service</h2>
        {/* <Row className={highlightRow}>
        <Col>
          <Image
            fluid
            src="http://placehold.jp/902x315.png"
          />
        </Col>
      </Row> */}
        <Row>
          <Col>
            <Carousel>
              {data.allFile.edges.map(({ node }, i) => {
                return (
                  <Carousel.Item key={i}>
                    <GatsbyImage className={carouselImage} image={node.childImageSharp.gatsbyImageData} />
                  </Carousel.Item>)
              })}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className={contentHolder}>
            <p>For all of our customers already in a home, we offer a comprehensive maintenance plan to keep your electrical system running in tip top shape,
            without breaking the bank. Doctor Electric is constantly given high praise for the responsive manner in which we treat all service calls. 
            We offer all types of Residential Electrical products and services. </p> 
            <h5>Specialties</h5>
            <ListGroup>
              {["Ceiling fans"," dimmer switches"," lighting upgrades"," smart switches"," recessed can lights"," new outlets"," USB outlets"," under cabinet lighting"," flood lights"," backup generators"," home surge protection"," electric car charges"].map((val, i) => (
                  
                  <ListGroup.Item className={serviceListItem} key={i}>{val.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</ListGroup.Item>
              ))}
              
            </ListGroup>
            <p></p>


            <p>On top of our wide range of services, we also offer a comprehensive maintenance plan. This plan is backed by the same service department as our builder warranties,
              so you know you'll get the same top quality service from new construction to remodel. 
            See below for a breakdown of our offerings
            </p>
        </Col>
        </Row>
        <Row>
          <Col className={exampleSection + " text-center"}>
            <h3>Maintenance Plan Tiers</h3>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h4 className="text-center">Basic ($125 per year)</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>Once a year visit to inspect your electrical panel</ListGroup.Item>
              <ListGroup.Item>Priority service when scheduling appointments</ListGroup.Item>
              <ListGroup.Item>Discounts on:
                <ListGroup variant="flush">
                  <ul>
                    <li>Emergency service (Offered 24/7)</li>
                    <li>Service call trip charge - $45 (normally $65)</li>
                    <li>10% off on additional work, up to $500 annually</li>
                  </ul>
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4 className="text-center">Silver ($180 per year)</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>Once a year visit to:
                <ul>
                  <li>Inspect your electrical panel</li>
                  <li>Change your smoke detector batteries</li>
                  <li>Check GFCI protected devices</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>Priority service when scheduling appointments</ListGroup.Item>
              <ListGroup.Item>Discounts on:
                <ListGroup variant="flush">
                  <ul>
                    <li>Emergency service (Offered 24/7)</li>
                    <li>Service call trip charge - $45 (normally $65)</li>
                    <li>15% off additional work, up to $1,000 annually</li>
                  </ul>
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4 className="text-center">Gold ($325 per year)</h4>
            <ListGroup variant="flush">
              <ListGroup.Item>Twice a year visit to:
                <ul>
                  <li>Inspect your electrical panel</li>
                  <li>Change your smoke detector batteries</li>
                  <li>Check GFCI protected devices</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>Priority service when scheduling appointments</ListGroup.Item>
              <ListGroup.Item>Discounts on:
                <ListGroup variant="flush">
                  <ul>
                    <li>Emergency service (Offered 24/7)</li>
                    <li>Service call trip charge - $45 (normally $65)</li>
                    <li>20% off additional work, up to $2,000 annually</li>
                  </ul>
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </div>
      <div className={contentHolder}>
        <h2 className={"page-title"}>Commercial Service</h2>
        {/* <Row className={highlightRow}>
        <Col>
          <Image
            fluid
            src="http://placehold.jp/902x315.png"
          />
        </Col>
      </Row> */}
        <Row>
          <Col className={contentHolder}>
            We can upgrade your commercial space as well. Upgrade light fixtures, extra outlets or circuits, need to remodel or repurpose your office space.
            We offer competitive pricing and we pride ourselves on our customer service and satisfaction.
          </Col>
        </Row>

      </div>


    </Layout>
  );
}

export default ServicePage

